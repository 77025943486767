<template>
  <div class="content">
    <el-row>
      <el-col :span="14" :xs="24">
        <div
          :class="show == true ? 'img' : '_img'"
          style="width:92%;height:70%;"
        >
          <img style="width:100%;height:80%" src="../assets/wl3.png" alt="" />
        </div>
      </el-col>
      <el-col :span="10" :xs="24">
        <div class="list" :class="show == true ? 'list' : '_img'">
          <ul>
            <li @click="li_one" :class="one == true ? 'li' : ''">综合</li>
            <li @click="li_two" :class="two == true ? 'li' : ''">新闻动态</li>
            <li @click="li_three" :class="three == true ? 'li' : ''">
              新闻公告
            </li>
          </ul>
        </div>
        <div v-if="one == true" :class="show == true ? 'item' : '_item'">
          <div
            v-if="comprehensiveData.length > 0"
            :class="show == true ? 'oneNews' : '_oneNews'"
          >
            <el-popover
              placement="bottom"
              :title="comprehensiveData[0].journalismTitle"
              width="400"
              trigger="click"
              :content="comprehensiveData[0].journalismDesc"
            >
              <span slot="reference"
                >{{ comprehensiveData[0].createTime | time
                }}{{ comprehensiveData[0].journalismTitle }}</span
              >
            </el-popover>
            <!-- @click="open(comprehensiveData[0])" -->
          </div>

          <div
            v-for="(item, index) in comprehensiveData.slice(1, 9)"
            :key="index"
            :class="show == true ? 'item_item' : 'itemItem'"
          >
            <el-popover
              placement="bottom"
              :title="item.journalismTitle"
              width="400"
              trigger="click"
              :content="item.journalismDesc"
            >
              <el-row slot="reference" class="itemTitle">
                <el-col :span="3" v-if="item.journalismTypeCode == 'XW01'"
                  ><span class="span">
                    新闻
                  </span></el-col
                >
                <el-col :span="3" v-if="item.journalismTypeCode == 'XW02'"
                  ><span class="span">
                    公告
                  </span></el-col
                >

                <el-col :span="15" class="hide">{{
                  item.journalismTitle
                }}</el-col>
                <el-col :span="6" class="itemRight">{{
                  item.createTime | time
                }}</el-col>
              </el-row>
            </el-popover>
          </div>
        </div>
        <div v-if="two == true" :class="show == true ? 'item' : '_item'">
          <div
            v-if="newsData.length > 0"
            :class="show == true ? 'oneNews' : '_oneNews'"
          >
            <el-popover
              placement="bottom"
              :title="newsData[0].journalismTitle"
              width="400"
              trigger="click"
              :content="newsData[0].journalismDesc"
            >
              <span slot="reference"
                >{{ newsData[0].createTime | time
                }}{{ newsData[0].journalismTitle }}</span
              >
            </el-popover>
          </div>
          <div
            v-for="(item, index) in newsData.slice(1, 6)"
            :key="index"
            :class="show == true ? 'item_item' : 'itemItem'"
          >
            <el-popover
              placement="bottom"
              :title="item.journalismTitle"
              width="400"
              trigger="click"
              :content="item.journalismDesc"
            >
              <el-row slot="reference" class="itemTitle">
                <el-col :span="3"
                  ><span class="span">
                    新闻
                  </span></el-col
                >
                <el-col :span="15" class="hide">{{
                  item.journalismTitle
                }}</el-col>
                <el-col :span="6" class="itemRight">{{
                  item.createTime | time
                }}</el-col>
              </el-row>
            </el-popover>
          </div>
        </div>
        <div v-if="three == true" :class="show == true ? 'item' : '_item'">
          <div
            v-if="noticeData.length > 0"
            :class="show == true ? 'oneNews' : '_oneNews'"
          >
            <el-popover
              placement="bottom"
              :title="noticeData[0].journalismTitle"
              width="400"
              trigger="click"
              :content="noticeData[0].journalismDesc"
            >
              <span slot="reference"
                >{{ noticeData[0].createTime | time
                }}{{ noticeData[0].journalismTitle }}</span
              >
            </el-popover>
          </div>
          <div
            v-for="(item, index) in noticeData.slice(1, 6)"
            :key="index"
            :class="show == true ? 'item_item' : 'itemItem'"
          >
            <el-popover
              placement="bottom"
              :title="item.journalismTitle"
              width="400"
              trigger="click"
              :content="item.journalismDesc"
            >
              <el-row slot="reference" class="itemTitle">
                <el-col :span="3"
                  ><span class="span">
                    公告
                  </span></el-col
                >
                <el-col :span="15" class="hide">{{
                  item.journalismTitle
                }}</el-col>
                <el-col :span="6" class="itemRight">{{
                  item.createTime | time
                }}</el-col>
              </el-row>
            </el-popover>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <el-dialog
      :fullscreen="false"
      :title="form.formTitle"
      :custom-class="'form-dialog'"
      width="650px"
      :center="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"
      :visible.sync="form.formShow"
      @close="formClosed"
    >
    </el-dialog> -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <span>{{ item }}</span>
    </el-dialog>
  </div>
</template>
<script>
import { BASE_URL, FACTORY_URL, MACHINE_API } from "../config";

export default {
  data() {
    return {
      dialogVisible: false,
      item: "",
      one: true,
      two: false,
      three: false,
      show: true,
      title: "",
      vm: {
        search: {
          //机构Id
          OrgId: "0",
          //租户Id
          TenantId: "0",
          // 新闻标题
          JournalismTitle: "",
          // 选中的id记录
          JournalismTypeCode: "XW01",
          ReleaseTimeStart: "",
          ReleaseTimeEnd: "",
        },
        pageIndex: 1,
        // 每页的数据
        pagesize: 6,
      },
      bm: {
        search: {
          //机构Id
          OrgId: "0",
          //租户Id
          TenantId: "0",
          // 新闻标题
          JournalismTitle: "",
          // 选中的id记录
          JournalismTypeCode: "XW02",
          ReleaseTimeStart: "",
          ReleaseTimeEnd: "",
        },
        pageIndex: 1,
        // 每页的数据
        pagesize: 6,
      },
      am: {
        search: { IsDelete: "" },
        pageIndex: 1,
        pageSize: 200,
        noCount: false,
      },
      newsData: [],
      allData: [],
      noticeData: [],
      arr: [],
      comprehensiveData: [],
    };
  },
  async created() {},
  async mounted() {
    setInterval(() => {
      this.widt();
    }, 100);
    this.li_one();
    await this.news();
    await this.notice();
    this.comprehensive();
  },
  methods: {
    widt() {
      if (window.innerWidth < 700) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    li_one() {
      this.one = true;
      this.two = false;
      this.three = false;
    },
    comprehensive() {
      let arr = this.arr;
      for (let a = 0; a < this.newsData.length; a++) {
        arr.push(this.newsData[a]);
      }
      this.comprehensiveData = arr;
    },
    open(data) {
      this.dialogVisible = true;
      this.title = data.journalismTitle;
      this.item = data.journalismDesc;
    },
    async news() {
      let url = `${BASE_URL}/api/home/PagingNews`;
      const res = await this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: this.vm,
      });
      this.newsData = res.data.content.rows;
      console.log(this.newsData, "///");
    },
    async notice() {
      let url = `${BASE_URL}/api/home/PagingNews`;
      const res = await this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: this.bm,
      });
      this.noticeData = res.data.content.rows;
      this.arr = res.data.content.rows.map((item) => {
        return item;
      });
      // this.arr = res.data.content.rows.map((obj) => {
      //   var robj = {};
      //   var journalismTypeCode = "journalismTypeCode";
      //   var journalismTitle = "journalismTitle";
      //   var createTime = "createTime";
      //   robj[journalismTitle] = obj.noticeTitle;
      //   robj[journalismTypeCode] = obj.noticeTypeCode;
      //   robj[createTime] = obj.createTime;
      //   return robj;
      // });
      // console.log(this.arr, "000111");
    },
    li_two() {
      // 新闻类别
      this.one = false;
      this.two = true;
      this.three = false;
    },
    li_three() {
      // 公告类别
      this.one = false;
      this.two = false;
      this.three = true;
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  width: 100%;
  margin-top: 20px;
  // padding-top: 20px;
}
.hide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.itemRight {
  text-align: right;
  font-size: 14px;
}
.item_item {
  border-bottom: 1px solid #ccc;
  // margin-right: 7.8125vw;
  margin-right: 4.6vw;
  // margin-right: 50px;
  padding: 5px 0;
}
li:hover {
  cursor: pointer;
}
.itemItem {
  // margin-left: 20px;
  margin-left: 3.125vw;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  // margin-right: 20px;
  margin-right: 3.125vw;
}
.oneNews {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  margin-right: 70px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #6282f5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._oneNews {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  // margin-left: 20px;
  margin-left: 3.125vw;
  margin-right: 3.125vw;
  // margin-right: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #6282f5;
}
.span {
  border: 1px solid #dfb184;
  font-size: 12px;
  display: inline-block;
  width: 26px;
  height: 15px;
  color: #dfb184;
  line-height: 16px;
  font-weight: 600;
  padding: 2px;
}
.item {
  text-align: left;
  margin-left: 10px;
}
._item {
  // margin-right: 20px;
  margin-right: 3.125vw;
  text-align: left;
}
.itemTitle {
  text-align: left;
}
.img {
  margin-left: 50px;
}
._img {
  margin-left: 3.125vw;
  // margin-left: 20px;
}
._list {
  margin-left: 0px;
}

.list {
  text-align: left;
}
ul {
  margin: 0px 0px 0px 10px;
  padding-inline-start: 0px;
}
// ._ul {
//   margin-left: 50px;
//   padding-inline-start: 0px;
// }
ul > li {
  display: inline-block;
  padding: 2px 0;
  margin-right: 50px;
  font-weight: 600;
  list-style: none;
}
.li {
  color: #6282f5;
  border-bottom: 1px solid #6282f5;
}
/deep/ .el-dialog__header {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid;
}
/deep/ .el-dialog__body {
  flex: 1;
  overflow: auto;
  padding: 20px 30px;
  text-align: left;
}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
</style>
